import React, {useEffect, useState, useMemo} from 'react';
import {Button, ButtonGroup, Subheading, TextField} from '@shopify/polaris';
import DiscountWarningBanner from '../discountWarningBanner/discountWarningBanner';
import update from 'immutability-helper';
import styled from 'styled-components';

const CouponWrapper = styled.div`
  margin-top: 1rem;
  
  .coupon-title {
    display: block;
    padding: 5px 0;
  }
  .coupon-items {
    display: flex;
    flex-direction: row;
    gap: 15px;
    .coupon-item:first-of-type {
      flex: 0 0 auto;
    }
    .coupon-item:nth-child(2) {
      width: 125px;
    }
    .coupon-item:last-of-type {
      flex: 1 1 auto;
    }
  }
`;

const DiscountCart = (props) => {
  const {state, setState, currency, currencySymbol, isAi = true, discountType = 'compareAtPrice', index = 0, isNewDiscountVersion = false} = props;
  const [isFirstButtonActive, setIsFirstButtonActive] = useState(false);
  useEffect(() => {
    const newValue = Array.isArray(state.product) && state.product.length > 0 && !isAi
      ? !state.product[index][discountType] ? true : !state.product[index][discountType]?.sym || state.product[index][discountType].sym === currencySymbol
      : !state[discountType] ? true : !state[discountType]?.sym || state[discountType].sym === currencySymbol;
    setIsFirstButtonActive(newValue);
  }, [state, discountType, setIsFirstButtonActive, index]);

  const handleFirstButtonClick = (index) => {
    const discount = {sym: currencySymbol, value: ''}
    if (Array.isArray(state.product) && state.product.length > 0 && !isAi) {
      setState(update(state, {
        [discountType]: {$set: discount},
        product: {[index]: {[discountType]: {$set: discount}}}
      }))
    } else {
      if (isFirstButtonActive) return;
      setState(update(state, {[discountType]: {$set: discount}}))
      setIsFirstButtonActive(true);
    }
  }

  const handleSecondButtonClick = (index) => {
    const discount = {sym: '%', value: ''}
    if (Array.isArray(state.product) && state.product.length > 0 && !isAi) {
      setState(update(state, {
        [discountType]: {$set: discount},
        product: {[index]: {[discountType]: {$set: discount}}}
      }))
    } else {
      if (!isFirstButtonActive) return;
      setState(update(state, {[discountType]: {$set: discount}}))
      setIsFirstButtonActive(false);
    }
  }

  const handleChangeDiscount = (value) => {
    const newVal = !isFirstButtonActive && value > 100 ? 100 : value;
    if (Array.isArray(state.product) && state.product.length > 0 && !isAi) {
      setState(update(state, {
        [discountType]: {$set: {
          sym: isFirstButtonActive ? currencySymbol : '%',
          value: newVal
        }},
        product: {
          [index]: {[discountType]: {$set: {
            sym: isFirstButtonActive ? currencySymbol : '%',
            value: newVal
        }}}}
      }))
    } else {
      setState(update(state, {
        [discountType]: {$set: {
          sym: isFirstButtonActive ? currencySymbol : '%',
          value: newVal
        }}
      }))
    }
  }

  const discountValue = useMemo(() => {
    if (Array.isArray(state.product) && state.product.length > 0 && !isAi) {
      return state.product[index][discountType] && state.product[index][discountType]?.sym
        ? String(state.product[index][discountType].value)
        : state.product[index][discountType] || ''
    } else {
      return state[discountType] && state[discountType]?.sym
        ? String(state[discountType].value)
        : state[discountType] || ''
    }
  }, [state]);

  return (
    <CouponWrapper className='discount-step'>
      {
        !isNewDiscountVersion &&
          <Subheading>
            {
              discountType === 'compareAtPrice' ? 'COMPARE AT PRICE' : 'DISCOUNT'
            }
          </Subheading>
      }
      <div className='coupon-items' style={{marginTop: '1rem'}}>
        <div className='coupon-item'>
          <ButtonGroup segmented>
            <Button pressed={isFirstButtonActive} onClick={() => handleFirstButtonClick(index)}>
              {currencySymbol}
            </Button>
            <Button pressed={!isFirstButtonActive} onClick={() => handleSecondButtonClick(index)}>
              %
            </Button>
          </ButtonGroup>
        </div>
        <div className='coupon-item'>
          <TextField
            onChange={(val) => handleChangeDiscount(val)}
            value={discountValue}
            min={0}
            max={!isFirstButtonActive ? 100 : null}
            type={'text'}
            suffix={isFirstButtonActive ? currency : '%'}
          />
        </div>
      </div>
      {
        ((state[discountType] && state[discountType]?.value) || isNewDiscountVersion) &&
          <DiscountWarningBanner isAi={isAi}/>
      }
    </CouponWrapper>
  );
};

export default DiscountCart;